<template>
    <div :class="columns.type">
        <component 
            :is="pagePlacementComponent" 
            :class="placement" 
            v-for="(placement,index) in columns.placements" 
            :key="index" 
            :placement_key="placement"
        />
    </div>
</template>

<script>
export default {
    name: "TemplateColumns",
    components: {
    },
    props: {
        columns: {
            type: Object,
            required: true,
        },
        pagePlacementComponent:  {
            type: Object,
            required: true,
        },
    }

}
</script>

<style scoped>
.main{
    width: 100%;
}

.left,.right,.both{
    display: flex;
    flex-direction: row;
}
.main{
    width: 100%;
}
.left .side_1,.left .side_2,.right .side_1,.right .side_2{
    width: 30%;
}
.left .main,.right .main{
    width: 70%;
}
.both .side_1,.both .side_2{
    width: 25%;
}
.both .main{
    width: 50%;
}


@media only screen and (max-width: 600px) {
    .left,.right,.both{
        flex-direction: column;    
    }
    
    .left .side_1,.left .side_2,.right .side_1,.right .side_2{
        width: 100%;
    }
    .left .main,.right .main{
        width: 100%;
    }
    .both .side_1,.both .side_2{
        width: 100%;
    }
    .both .main{
        width: 100%;
    }
}

</style>