import { computed } from "@vue/runtime-core";

export default function(page){
    const templateType = computed(() => {
        switch(page.value.template.item_key){
            case 'default': 
            case 'right_side_column': 
            case 'right_side_column_header': 
            case 'right_side_column_footer': 
            case 'right_side_column_header_footer': 
            case 'left_side_column':
            case 'left_side_column_header':
            case 'left_side_column_footer':
            case 'left_side_column_header_footer':
            case 'both_side_column':
            case 'both_side_column_header':
            case 'both_side_column_footer':
            case 'both_side_column_header_footer':
                return 'default';
            default:
                return '';
        }
    });
    return {
        templateType
    }
}
