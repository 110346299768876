<template>
    <component 
        :is="pagePlacementComponent"
        v-if="hasPlacement('header')"
        :class="'header'" 
        :placement_key="'header'"
    />
    <TemplateColumns :columns="columns" :pagePlacementComponent="pagePlacementComponent" />
    <component 
        :is="pagePlacementComponent"
        v-if="hasPlacement('footer')"
        :class="'footer'" 
        :placement_key="'footer'"
    />     
    
</template>

<script>
import { computed } from 'vue';
import TemplateColumns from '@common/components/cms/templates/TemplateColumns';

export default {
    name: "TemplateDefault",
     components: {
        TemplateColumns,
        // PagePlacementAdmin: defineAsyncComponent(() => import(/* webpackChunkName: "page-placement-admin" */'@admin/components/cms/content/PagePlacementAdmin')),        
    },
    props: {
        page: {
            type: Object,
            required: true,
        },
        pagePlacementComponent:  {
            type: Object,
            required: true,
        },
    },
    setup(props){
        const template = computed(() => props.page.template);

        const columns = computed(() => {
            let _columns = {};
            switch(template.value.item_key){
                case 'default': 
                    _columns.placements = ['main'];
                    _columns.type = 'single';
                    break;
                case 'right_side_column': 
                case 'right_side_column_header': 
                case 'right_side_column_footer': 
                case 'right_side_column_header_footer': 
                    _columns.placements = ['main','side_1'];
                    _columns.type = 'right';
                    break;
                case 'left_side_column':
                case 'left_side_column_header':
                case 'left_side_column_footer':
                case 'left_side_column_header_footer':
                    _columns.placements = ['side_1','main'];
                    _columns.type = 'left';
                    break;
                case 'both_side_column':
                case 'both_side_column_header':
                case 'both_side_column_footer':
                case 'both_side_column_header_footer':
                    _columns.placements = ['side_1','main','side_2'];
                    _columns.type = 'both';
                    break;
                default:
                    break;
            }
            // _template.placements = _template.placements.filter((item) => _list_of_keys.includes(item.item_key)).sort(function(a, b){
            //     return _list_of_keys.indexOf(a.item_key) - _list_of_keys.indexOf(b.item_key);
            // });

            return _columns;
        });

        const hasPlacement = (placement) => {
            return template.value.placements.some((element) => element.item_key === placement);
        }

        return {
            columns,
            template,
            hasPlacement
        };
    }
}
</script>

<style scoped>

</style>