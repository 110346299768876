<template>
    <component :is="tag" class="loaded-content" v-bind="$attrs" v-if="loaded">
        <slot></slot>
    </component>
    <div class="loader" v-else-if="error">
        <BaseIcon class="error" />
    </div>
    <div class="loader" v-else>
        <BaseLoader />
    </div>
</template>

<script>
export default {
    name: "LoadedContent",
    props: {
        loaded: Boolean,
        error: Boolean,
        tag: {
            type: String,
            default: () => 'div'
        }
    }

}
</script>

<style scoped>
.loader{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
.loaded-content{
    display: flex;
    flex-direction: column;

}

</style>