<template>
    <div class="main-banner">
        <div class="page-container">
            <div class="title-wrap">
                <div class="title">
                    AgneWeb
                </div>
                <div class="subtitle">
                    {{ $t('title.web_application_development')}}
                </div>
            </div>
            <BricksGroup class="bricks-wrap" :group="rows" />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import BricksGroup from './BricksGroup'

export default {
    name: "MainBanner",
    components: {
        BricksGroup
    },
    setup(){

        const rows = ref({
            direction: 0,
            bricks: 11,
            darks: [2,7],
            group: {
                direction: 1,
                bricks: 5,
                darks: [4],
                group: {
                    direction: 0,
                    bricks: 6,
                    darks: [3],
                    group: {
                        direction: 1,
                        bricks: 3,
                        darks: [1],
                        group: {
                            direction: 0,
                            bricks: 3,
                            group: {
                                direction: 1,
                                bricks: 1,
                                group: {
                                    direction: 0,
                                    bricks: 1,
                                }
                            }
                        }
                    }
                }
            }
        })

        return {
            rows,
        }
    }
}
</script>

<style scoped>
.main-banner{
    width: 100%;
    background: #177542;
    background: var(--primary-color);
    color: #E2E8D4;
    color: var(--light-color);
    padding-bottom: 1rem;
    background-color: #177542;
    background-image:  
         radial-gradient(at 40% 40%,rgb(23 117 66 / 53%) 30%,rgb(52 75 27 / 56%)),
        var(--bg-pattern);
    background-size:   100% 100%,var(--bg-pattern-size);
    margin-bottom: 3rem;
}
.page-container{
    height: 30rem;
    border: 5px solid #E2E8D4;
}
.title{
    font-family: 'Orbitron', sans-serif;
    font-size: 5rem;
}
.subtitle{
    text-align: right;
}

/* .bricks{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
} */
.bricks-wrap{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
}
.title-wrap{
    position: relative;
    left: 15%;
    top: 25%;
    width: fit-content;
}


@media screen and (max-width: 800px) {
    .title{
        font-size: 10vw;
    }
    .page-container{
        height: 60vw;
    }
}

@media screen and (max-width: 600px) {
    /* .title{
        font-size: 3rem;
    } */
    /* .page-container{
        height: 60vw;
    } */
}
</style>