<template>
    <div class="bricks-group" :class="{row: group.direction === 1, column: group.direction === 0}">
        <div class="bricks">
            <div class="brick" v-for="brick_index in group.bricks" :key="brick_index" :class="{dark: group.darks && group.darks.includes(brick_index)}">
            </div>
        </div>
        <BricksGroup v-if="group.group" :group="group.group" />
    </div>
</template>

<script>
export default {
    name: "BricksGroup",
    props: {
        group: Object
    }
}
</script>

<style scoped>
.brick{
    background: #E2E8D4;
    background: var(--light-color);
    margin: 0.3rem;
    float: right;
    transition: opacity 1.0s ease;
    
    box-shadow: 1px 1px 10px -2px #344b1b;
}
.brick.dark{
    background: #344b1b;
}
.brick:hover{
    opacity: 0.3;
}
.bricks-group{
    display: flex;
}
.bricks-group.column{
    flex-direction: column-reverse;
}
.bricks-group.row{
    flex-direction: row-reverse;
}
.bricks-group>.bricks{
    display: flex;
}
.bricks-group.column>.bricks{
    flex-direction: row-reverse;
}
.bricks-group.row>.bricks{
    flex-direction: column-reverse;
}
.bricks-group.column>.bricks>.brick{
    width: 3.5rem;
    height: 1.3rem;
}
.bricks-group.row>.bricks>.brick{
    height: 3.5rem;
    width: 1.3rem;
}

@media screen and (max-width: 800px) {
    .bricks-group.column>.bricks>.brick{
        width: 6vw;
        height: 2.22vw;
    }
    .bricks-group.row>.bricks>.brick{
        height: 6vw;
        width: 2.22vw;
    }
    .brick{
        margin: 0.6vw;
    }
}

</style>