<template>
    <div class="home">
        <MainBanner />
        <div class="page-container">
            <PageView :customBlocks="customBlocks" />
        </div>
    </div>
</template>

<script>
import MainBanner from '@agneweb/components/graphics/MainBanner';
import PageView from '@common/components/cms_app/PageView.vue';
import { defineAsyncComponent } from 'vue';

export default {
    name: "Home",
    components: {
        MainBanner,
        PageView
    },
    setup() {
        const customBlocks = {
            "development-path": defineAsyncComponent(() => import(/* webpackChunkName: "development-path" */'@agneweb/components/blocks/BlockDevelopmentPath'))
        };

        return {
            customBlocks
        }
    }
};
</script>

<style scoped>
</style>
